/* GmailAuth.css */

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-image: url('../public/email-labeler-transparent.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 20%; 
}
  
.container {
    background-color: rgba(255, 255, 255, 0.8); 
    padding: 30px; 
    border-radius: 10px;
    z-index: 1;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
    text-align: center; 
}
  
.title {
    margin-bottom: 20px;
    color: #3498db; 
    font-size: 24px; 
}
  
.form {
    width: 300px; 
}
  

.link-container {
    margin-top: 20px; 
    width: 100%; 
    text-align: center; /* Center the link within its container */
}
  
.link-container a {
    display: inline-block; /* Make the link behave like a block element */
}
  
/* GmailAuth.css */
.main .container .link-container {  
    margin-top: 20px; 
    width: 100%; 
    text-align: center; 
  }
  
  .main .container .link-container a {
    display: inline-block;
  }

  .link-container .ant-btn-primary {
    background-color: #2ecc71; /* Or your desired green color */
    border-color: #27ae60; /* Adjust border color if needed */
  }