/*body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../public/email-labeler-transparent.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 20%;
  opacity: 0.3; 
  z-index: -1;  
}
*/

body {
  background-color: white;
}


.container {
  position: relative; /* Change to relative positioning */
  top: auto; /* Remove vertical centering */
  left: auto;
  transform: none; 
}

